/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

/**
 * Allows route changes without updating scroll position.
 * (E.g. for URL query params.)
 *
 * https://www.joshwcomeau.com/gatsby/the-worlds-sneakiest-route-change/
 */
export const shouldUpdateScroll = ({ routerProps }) => {
  return routerProps?.location?.state?.disableScrollUpdate ? false : true;
};
